const SpecialtiesList = {
  cardiorespiratory: "Cardiorespiratory 🫀",
  gastro: "Gastrointestinal 🤮",
  neuro: "Neurology 🧠",
  haem: "Haematology 🩸",
  id: "Infectious Diseases 🦟",
  renal: "Renal 🚰",
  rheum: "Rheumatology 🤷",
  endo: "Endocrinology 🏃",
  derm: "Dermatology 💁‍♀️",
  ent: "ENT 👂",
  gp: "GP 👨‍⚕️",
  gynae: "Gynaecology 😰",
  obstetrics: "Obstetrics 🤰",
  ophthal: "Ophthalmology 👁",
  paeds: "Paediatrics 👶",
  psych: "Psychiatry 😐",
  breast: "Breast 🤱",
  generalsurg: "General Surgery 💉",
  traumaortho: "Trauma and Orthopaedics 🦴",
  urology: "Urology 🍆",
  vascular: "Vascular surgery 🪡",
  miscellaneous: "Miscellaneous 🗣",
  ethics_law: "Ethics and law 👩‍⚖️",
};

export const SpecialtiesListNoEmoji = {
  cardiorespiratory: "Cardiorespiratory",
  gastro: "Gastrointestinal",
  neuro: "Neurology",
  haem: "Haematology",
  id: "Infectious Diseases",
  renal: "Renal",
  rheum: "Rheumatology",
  endo: "Endocrinology",
  derm: "Dermatology",
  ent: "ENT",
  gp: "GP",
  gynae: "Gynaecology",
  obstetrics: "Obstetrics",
  ophthal: "Ophthalmology",
  paeds: "Paediatrics",
  psych: "Psychiatry",
  breast: "Breast",
  generalsurg: "General Surgery",
  traumaortho: "Trauma and Orthopaedics",
  urology: "Urology",
  vascular: "Vascular surgery",
  miscellaneous: "Miscellaneous",
  ethics_law: "Ethics and law",
};

export default SpecialtiesList;
