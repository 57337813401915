import { useStaticQuery, graphql } from "gatsby";

export const useSiteStations = () => {
  const { allStrapiStations } = useStaticQuery(
    graphql`
      query SiteStations {
        allStrapiStations(filter: { coming_soon: { eq: false } }) {
          nodes {
            type
            Specialty
          }
        }
      }
    `
  );
  return allStrapiStations;
};
