import {
  ConstructionOutlined,
  SettingsSystemDaydreamRounded,
} from "@mui/icons-material";
import { navigate } from "gatsby-link";
import React, { useContext, useEffect, useState } from "react";
import { render } from "react-dom";
import { FirebaseContext } from "../components/Firebase";
import Header from "../components/Header";
import SpecialtySelection from "../components/SpecialtySelection";
import ThickLoadingWall from "../components/ThickLoadingWall";
import Billing from "../containers/Dashboard/Billing";
import Performance from "../containers/Dashboard/Performance";
import Profile from "../containers/Dashboard/Profile";
import Referral from "../containers/Dashboard/Referral";
import Pricing from "../containers/Home/Pricing";
import Usps from "../containers/Home/Usps";
import automatedRedirects from "../helpers/automatedRedirects";

function Dashboard({ location }) {
  const defaultSection =
    (location.state && location.state.defaultSection) || "dashboard";
  const { user, firebase, loading, serverTime } = useContext(FirebaseContext);
  const [name, setName] = useState("");
  const [referralNumber, setReferralNumber] = useState(0);
  const [section, setSection] = useState(defaultSection);
  const [performanceData, setPerformanceData] = useState({});

  function renderSwitch(param) {
    switch (param) {
      case "dashboard":
        return <SpecialtySelection user={user}/>;
        break;
      // case "billing":
      //   return (
      //     <Billing user={user} serverTime={serverTime} firebase={firebase} />
      //   );
      //   break;
      case "referral":
        return (
          <Referral
            link={user.referralLink}
            referralNumber={referralNumber}
            user={user}
          />
        );
        break;
      case "profile":
        return (
          <Profile user={user} firebase={firebase} serverTime={serverTime} />
        );
        break;
      case "subscribe":
        return <Pricing />;
        break;
      case "performance":
        return <Performance data={performanceData} />;
        break;

      default:
        break;
    }
  }
  useEffect(() => {
    if (!loading) {
      automatedRedirects(user);
      if (user) {
        setName(user.firstname);
        firebase.getNumberOfMonthsReferred({ user }).then((number) => {
          setReferralNumber(number);
        });
        firebase
          .retrieveStationsData()
          .then((response) => setPerformanceData(response));
      } else {
        navigate("/");
      }
    }
  }, [loading]);
  return (
    <div>
      {loading && <ThickLoadingWall />}
      <Header
        title={`Hello ${name} 👋`}
        subheading="Start OSCE revision, manage your account and get UNLIMITED free months in one place."
      />
      <div class="section-browse-dashboard wf-section">
        <div class="browse-container w-container">
          <div class="w-tabs">
            <div class="tabs-menu-3 w-tab-menu">
              <a
                onClick={() => setSection("dashboard")}
                class={
                  section == "dashboard"
                    ? "dashboard-tab w-inline-block w-tab-link w--current"
                    : "dashboard-tab w-inline-block w-tab-link"
                }
              >
                <div>Dashboard</div>
              </a>
              <a
                onClick={() => setSection("performance")}
                class={
                  section == "performance"
                    ? "dashboard-tab w-inline-block w-tab-link w--current"
                    : "dashboard-tab w-inline-block w-tab-link"
                }
              >
                <div>Performance</div>
              </a>
              {/* <a
                onClick={() => setSection("billing")}
                class={
                  section == "billing"
                    ? "dashboard-tab w-inline-block w-tab-link w--current"
                    : "dashboard-tab w-inline-block w-tab-link"
                }
              >
                <div>Billing</div>
              </a> */}
              <a
                onClick={() => setSection("profile")}
                class={
                  section == "profile"
                    ? "dashboard-tab w-inline-block w-tab-link w--current"
                    : "dashboard-tab w-inline-block w-tab-link"
                }
              >
                <div>Account</div>
              </a>
              <a
                onClick={() => setSection("referral")}
                class={
                  section == "referral"
                    ? "dashboard-tab w-inline-block w-tab-link w--current"
                    : "dashboard-tab w-inline-block w-tab-link"
                }
              >
                <div>Unlimited Free Months</div>
              </a>
              <a
                onClick={() => setSection("subscribe")}
                class={
                  section == "subscribe"
                    ? "special-tab w-inline-block w-tab-link w--current"
                    : "special-tab w-inline-block w-tab-link"
                }
              >
                <div class="subscribe-text">Subscribe 🌟</div>
              </a>
            </div>
            {renderSwitch(section)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
