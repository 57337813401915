import React from "react";

function Header({ title, subheading, darkMode }) {
  return (
    <div
      class={
        darkMode ? "page-header dark wf-section" : "page-header wf-section"
      }
    >
      <h1 class={darkMode ? "h1-title light" : "h1-title"}>{title}</h1>
      <p class={darkMode ? "subheading-text light" : "subheading-text centre"}>
        {subheading}
      </p>
    </div>
  );
}

export default Header;
