export const typeList = {
  history: "History ✍🏼",
  examination: "Examination 🩺",
  emergency: "Emergency 🚑",
  explanation: "Explanation 🗣",
  data_interpretation: "Data Interpretation 📈",
  mixed: "Mixed ♾",
  communication: "Communication 📣",
  ethics_law: "Ethics and Law ⚖️",
  procedures: "Procedures 💉",
};
