import React from "react";
import Linear from "../Progressbar/Linear";

function ScoreCards({ title, totalStations, stationsDone, percentage }) {
  return (
    <div
      id="w-node-df9938e6-eb64-c15e-a616-5ba3831b4116-831b4114"
      class="station-type-card"
    >
      <div class="text-block-11">{title}</div>
      <div
        id="w-node-df9938e6-eb64-c15e-a616-5ba3831b4118-831b4114"
        class="text-block-9"
      >
        {`${stationsDone}/${totalStations} STATIONS COMPLETE`}
      </div>
      
        <Linear value={percentage/100}/>

    </div>
  );
}

export default ScoreCards;
