import React from "react";
import Diagnosis from "images/webflow/diagnosis.png";
import Improvement from "images/webflow/improvement.png";
import Target from "images/webflow/target.png";

function Usps() {
  return (
    <div class="usps-section wf-section">
      <div class="usps-container w-container">
        <div class="w-row">
        </div>    
        <div class="w-col w-col-4">
          <div class="usp-div">
            <div class="usp-icon">
              <img src={Target} loading="lazy" alt="" class="usp-image" />
            </div>
            <div class="usp-title">Accurate scores</div>
            <div class="usp-text">
            Our smart mark schemes are threshold-based, reflecting how medical schools truly mark OSCE stations.
            </div>
          </div>
        </div>
        <div class="w-col w-col-4">
          <div class="usp-div">
            <div class="usp-icon">
              <img src={Improvement} loading="lazy" alt="" class="usp-image" />
            </div>
            <div class="usp-title">First-decile strategy</div>
            <div class="usp-text">
              Easily log your scores and differentiate between low- and high-performing stations to target your weaknesses.
            </div>
          </div>
        </div>
        <div class="w-col w-col-4">
          <div class="usp-div">
            <div class="usp-icon">
              <img
                src={Diagnosis}
                loading="lazy"
                alt=""
                class="usp-image rectangle"
              />
            </div>
            <div class="usp-title">Diagnostic expert</div>
            <div class="usp-text">
              Listen to murmurs, interpret CXRs and read ECGs. Integrated media to help you become a diagnostic expert.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Usps;
