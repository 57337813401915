import { navigate } from "gatsby-link";
import React, { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../components/Firebase";
import LoadingWall from "../../components/LoadingWall";
import { createCheckoutSession } from "../../components/Stripe/createCheckoutSession";

function Pricing() {
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [internalLoading, setInternalLoading] = useState(false);
  const [referred, setReferred] = useState(false);
  const [betaDealsLeft, setBetaDealsLeft] = useState(0);

  // useEffect(() => {
  //   if (window.Rewardful && window.Rewardful.affiliate.id) {
  //     //console.log("there is rewardful");
  //     setReferred(true);
  //   }
  //   //console.log("checked for rewardful");
  // }, [loading]);

  useEffect(() => {
    if (!loading) {
      firebase.db
        .collection("limited_stock_items")
        .doc("beta")
        .onSnapshot((doc) => {
          setBetaDealsLeft(doc.data().amount);
        });
      if (user) {
        if (user.referredBy && user.referredBy != "organic") {
          setReferred(true);
        }
      }
    }
  }, [loading]);

  function handleClick(plan) {
    setInternalLoading(true);
    if (user) {
      createCheckoutSession(user.uid, user.stripeId, plan);
    } else {
      navigate("/registration");
    }
  }

  return (
    <div class="pricing-section wf-section">
      {internalLoading && <LoadingWall />}
      <div id="prices" class="pricing-container w-container gap">
        <h2 class="heading centre">Simple, transparent pricing.</h2>
        <div class="subheading-text centre">
          At OSCEhub we like to keep things simple. £29.99 one-off payment with no recurring fees.
          {/* <br></br>
          <br></br>
          Now get OSCEhub subscription from only £2 (x3 monthly instalments) with Klarna! */}
          {/* <br></br> */}
          {/* Discounted group pricing available for groups of{" "}
          <u>
            <a
              class="group-button"
              href="https://buy.stripe.com/5kA3dwdgO2ZX9ig7ss"
            >
              two
            </a>
          </u>
          ,{" "}
          <u>
            <a
              class="group-button"
              href="https://buy.stripe.com/4gw6pIa4Casp0LKaEF"
            >
              three
            </a>
          </u>{" "}
          and{" "}
          <u>
            <a
              class="group-button"
              href="https://buy.stripe.com/fZe3dw1y6441520dQS"
            >
              four
            </a>
          </u> */}
        </div>
        <div class="pricing-columns w-row">
          {/* <div class="column-3 w-col w-col-4">
            <div class="pricing-1-div">
              <div class="pricing-title">3-month plan</div>
              <div class="price">
                {referred ? (
                  <>
                    <span class="text-span"></span>
                    <span class="text-span-struckthrough">£6</span>4.80
                  </>
                ) : (
                  <>
                    <span class="text-span">£</span>6
                  </>
                )}
              </div>
              <div class="line white-background"></div>
              <div class="checklist-pricing">
                <div class="checklist-text">
                  <div class="pricing-text-checkboxes-reversed">
                    Last minute preparation.
                    <br></br>
                    <br></br>
                    £2 (x3 monthly instalments) with Klarna
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  referred ? handleClick("3-referred") : handleClick(3);
                }}
                class="button centre w-button"
              >
                Choose Plan
              </button>
            </div>
          </div>
          <div class="column-3 w-col w-col-4">
            <div class="pricing-1-div">
              <div class="pricing-title">6-month plan</div>
              <div class="price">
                {referred ? (
                  <>
                    <span class="text-span"></span>
                    <span class="text-span-struckthrough">£9 </span>7.20
                  </>
                ) : (
                  <>
                    <span class="text-span">£</span>9
                  </>
                )}
              </div>
              <div class="line white-background"></div>
              <div class="checklist-pricing">
                <div class="checklist-text">
                  <div class="pricing-text-checkboxes-reversed">
                    Practise makes perfect.
                    <br></br>
                    <br></br>
                    £3 (x3 monthly instalments) with Klarna
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  referred ? handleClick("6-referred") : handleClick(6);
                }}
                class="button centre w-button"
              >
                Choose Plan
              </button>
            </div>
          </div> */}
          <div class="column-3 w-col w-col-4 main-box">
            <div class="pricing-main-div">
              <div class="pricing-title purple">12-month access</div>
              <div class="price purple">
                {referred ? (
                  <>
                    <span class="text-span"></span>
                    <span class="text-span-struckthrough">£12 </span>9.60
                  </>
                ) : (
                  <>
                    <span class="text-span">£</span>29.99
                  </>
                )}{" "}
              </div>
              <div class="line blue-background"></div>
              <div class="checklist-pricing">
                <div class="checklist-text">
                  <div class="pricing-text-checkboxes">
                    Early bird catches the worm.
                    <br></br>
                    {/* £4 (x3 monthly instalments) with Klarna */}
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  referred ? handleClick("12-referred") : handleClick(12);
                }}
                class="button-invert-colour centre w-button"
              >
                Purchase
              </button>
            </div>
          </div>
          <div class="column-3 w-col w-col-4 hide">
            <div class="pricing-main-div">
              <div class="pricing-title purple minimise">
                <strike class="minimise">12-month plan</strike>
              </div>
              <br></br>
              <div class="pricing-title purple">One-Off Lifetime Plan</div>
              <div class="price purple">
                <>
                  <span class="text-span">£</span>
                  45
                </>
              </div>
              <div class="line blue-background"></div>
              <div class="checklist-pricing">
                <div class="checklist-text">
                  <div class="pricing-text-checkboxes">
                    Get access to OSCEhub forever for a one-off payment.
                    Exclusive to our BETA users.
                    <br></br>
                    <br></br>
                    <u>1 day left</u>
                    <br></br>
                    <br />
                  </div>
                </div>
              </div>
              <button
                onClick={() => handleClick("beta")}
                class="button-invert-colour centre w-button"
              >
                Get It Quick!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
