import React, { useState } from "react";
import styled from "styled-components";

const StyledH2 = styled.h2`
  font-size: 16px;
  margin-bottom: 0px;
`;

function Referral({ link, referralNumber, user }) {
  const [emailAddress, setEmailAddress] = useState(user.email);
  const [firstname, setFirstname] = useState(user.firstname);
  const [surname, setSurname] = useState(user.lastname);
  function handleInputChange(e) {
    e.persist();
    setEmailAddress(e.target.value);
  }
  function handleFNInputChange(e) {
    e.persist();
    setFirstname(e.target.value);
  }
  function handleSNInputChange(e) {
    e.persist();
    setSurname(e.target.value);
  }
  return (
    <>
      <div class="referrals">
        <h2 class="h2-account-page">Get UNLIMITED free months 🎉</h2>
        <div class="free-months-text">Number of free months:</div>
        <div class="free-months">{referralNumber}</div>
        <div class="subheading-text">
          Share your personal link with your friends and peers and get a free
          month for each person who signs up. They receive a 20% discount!
        </div>
        <div class="text-block-8">{link}</div>
        <a
          class="button-copy-link w-button"
          onClick={() => navigator.clipboard.writeText(link)}
        >
          Copy Link
        </a>
      </div>
      <br />
      <div class="referrals">
        <h2 class="h2-account-page">Join us as a writer ✍️</h2>
        <div class="subheading-text">
          Would you like to join the team at OSCEhub as a station writer?
        </div>
        <div class="subheading-text">
          Enjoy free access, certificates for your portfolio as well as a
          competitive salary. Sign up today 😎
        </div>
        <form
          action="https://getform.io/f/4aae4a05-13dd-4321-82a5-10301f0284fc"
          method="POST"
        >
          <StyledH2>First Name</StyledH2>
          <input
            type="text"
            name="firstname"
            class="form-field w-input"
            value={firstname}
            onChange={handleFNInputChange}
          />
          <StyledH2>Surname</StyledH2>
          <input
            type="text"
            name="surname"
            class="form-field w-input"
            value={surname}
            onChange={handleSNInputChange}
          />
          <StyledH2>Contact Email</StyledH2>
          <input
            type="email"
            name="email"
            class="form-field w-input"
            value={emailAddress}
            onChange={handleInputChange}
          />
          <button class="button top-displacement w-button" type="submit">
            Send
          </button>
        </form>
      </div>
    </>
  );
}

export default Referral;
