import React, { useEffect, useState } from "react";

function Billing({ user, serverTime, firebase }) {
  const [expired, setExpired] = useState(false);
  const expiryTime = user.expiryTime.toMillis();
  useEffect(() => {
    expiryTime < serverTime && setExpired(true);
  }, []);
  //console.log(expiryTime);
  //console.log(serverTime);
  return (
    <div data-w-tab="Billing">
      <div class="div-block-2">
        <h2 class="h2-account-page">Billing</h2>
        <div class="payment">{user.latestPlan}</div>
        {!expired ? (
          <div class="text-block-7">ACTIVE PLAN</div>
        ) : (
          <div class="text-block-7 error">PLAN EXPIRED</div>
        )}
        <div class="plan-renewal">
          {expiryTime < 4418238086000 ? (
            <>
              Your plan {!expired ? "ends" : "ended"} on{" "}
              {user.expiryTime.toDate().toDateString()}
            </>
          ) : (<>We are forever grateful for your early support</>)}
        </div>
        {/* <a onClick={() => firebase.customerPortalRedirect()} class="button w-button">
          Update Plan
        </a>
        <a href="#" class="button invert-colours w-button">
          Cancel Plan
        </a> */}
      </div>
    </div>
  );
}

export default Billing;
