import React from "react";

function automatedRedirects(user) {
  if (!user) {
    window.location.href = "/";
    // //console.log('redirect /')
    return;
  }
  if (!user.emailVerified) {
    // //console.log('redirect /verify')
    window.location.href = "/verifyEmail";
    return;
  } else {
    if (
      user.referredBy == null &&
      window.location.pathname != "/registrationSuccess/"
    ) {
      window.location.href = "/registrationSuccess/"
    }
  }
}

export default automatedRedirects;
