import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ThickLoadingWall from "../../components/ThickLoadingWall";
import errorDictionary from "../../helpers/errorDictionary";

const StyledH2 = styled.h2`
  font-size: 16px;
  margin-bottom: 0px;
`;

const FormContainer = styled.form`
  margin-right: 5vw;
`;

const ErrorMessageText = styled.div`
  color: red;
  text-align: center;
`;

function Profile({ user, firebase, serverTime }) {
  const [formValues, setFormValues] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [expired, setExpired] = useState(false);
  const expiryTime = user.expiryTime.toMillis();
  useEffect(() => {
    expiryTime < serverTime && setExpired(true);
  }, []);

  function handleInputChange(e) {
    e.persist();
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    firebase.updateProfile({
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      userId: user.uid,
    });
  }

  function handleSubmitForPasswordChange(e) {
    e.preventDefault();
    //console.log("handle submit clicked");
    if (formValues.password !== formValues.confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    if (formValues.password == "") {
      return;
    }
    setLoading(true);
    firebase.auth.currentUser
      .updatePassword(formValues.password)
      .then(() => {
        //console.log("update successful");
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setErrorMessage(errorDictionary[error.code]);
        setLoading(false);
      });
  }

  return (
    <div>
      {/* <h2 class="h2-account-page">Profile</h2> */}
      {loading && <ThickLoadingWall />}
      <div data-w-tab="Billing">
        <div class="div-block-2">
          <h2 class="h2-account-page">Billing</h2>
          <div class="payment">{user.latestPlan}</div>
          {!expired ? (
            <div class="text-block-7">ACTIVE PLAN</div>
          ) : (
            <div class="text-block-7 error">PLAN EXPIRED</div>
          )}
          <div class="plan-renewal">
            {expiryTime < 4418238086000 ? (
              <>
                Your plan {!expired ? "ends" : "ended"} on{" "}
                {user.expiryTime.toDate().toDateString()}
              </>
            ) : (
              <>We are forever grateful for your early support</>
            )}
          </div>
          <br/>
          <h2 class="h2-account-page">Profile</h2>
          <div class="w-profile-form">
            <FormContainer
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              onSubmit={handleSubmit}
            >
              <StyledH2>First name</StyledH2>
              <input
                type="text"
                class="form-field w-input"
                maxlength="256"
                name="firstname"
                value={formValues.firstname}
                onChange={handleInputChange}
                id="firstname"
              />
              <StyledH2>Surname</StyledH2>
              <input
                type="text"
                class="form-field w-input"
                maxlength="256"
                name="lastname"
                value={formValues.lastname}
                onChange={handleInputChange}
                id="lastname"
              />
              <StyledH2>Email</StyledH2>
              <input
                type="email"
                class="form-field w-input"
                maxlength="256"
                name="email"
                data-name="Email"
                placeholder={user.email}
                id="email"
                required=""
                disabled={true}
              />
              <input
                type="submit"
                value="Save"
                data-wait="Please wait..."
                class="button profile w-button"
              />
            </FormContainer>
            <FormContainer
              id="password-form"
              name="password-form"
              method="get"
              onSubmit={handleSubmitForPasswordChange}
            >
              <StyledH2>New password</StyledH2>
              <input
                type="password"
                class="form-field w-input"
                maxlength="256"
                name="password"
                value={formValues.password}
                onChange={handleInputChange}
                id="password"
              />
              <StyledH2>Confirm New Password</StyledH2>
              <input
                type="password"
                class="form-field w-input"
                maxlength="256"
                name="confirmPassword"
                value={formValues.confirmPassword}
                onChange={handleInputChange}
                id="confirmPassword"
              />
              {!!errorMessage && (
                <div>
                  <ErrorMessageText>{errorMessage}</ErrorMessageText>
                </div>
              )}
              <br />
              <input
                type="submit"
                value={success ? "Password set" : "Set password"}
                class={
                  success
                    ? "button-success top-displacement w-button"
                    : "button top-displacement w-button"
                }
                disabled={success ? true : false}
              />
            </FormContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
