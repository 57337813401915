import React from "react";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { borderRight } from "@mui/system";

const LinearProgressbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify: space-between;
  width: 100%;
  margin: 7px;
  /* justify-content: space-between; */
`;

const LinearBarSpan = styled.span`
  font-weight: 800px;
  color: #605b7c;
  font-size: calc(0.4vw + 10px);
  width: calc(90px + 1vw);
  text-align: left;
  @media (max-width: 750px) {
    min-width: calc(10vw);
  }
  @media (min-width: 1400px) {
    font-size: 12.5px;
  }
`;
const LinearBarSpanPercentages = styled.span`
  font-weight: 800px;
  color: #605b7c;
  font-size: calc(0.4vw + 10px);
  min-width: 20px;
  text-align: right;
  margin-left: 5px;
`;

const BorderLinearProgress = muiStyled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 100,
  margin: "3px",
  minWidth: "70%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#c4c2da",
  },
  [`&.${linearProgressClasses.colorSecondary}`]: {
    backgroundColor: "#c4c2da",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 100,
    backgroundColor: "#1e173f",
  },
}));

function Linear({ title, value }) {
  return (
    <div>
      <LinearProgressbarContainer>
        {title && <LinearBarSpan>{title}</LinearBarSpan>}
        <BorderLinearProgress variant="determinate" value={value * 100} />
        <LinearBarSpanPercentages>{`${Math.round(
          value * 100
        )}%`}</LinearBarSpanPercentages>
      </LinearProgressbarContainer>
    </div>
  );
}

export default Linear;
