const errorDictionary = {
  "auth/email-already-in-use":
    "The email address is already in use by another account",
  "auth/user-not-found":
    "This email is not yet registered, please register first",
  "auth/invalid-email": "Please check you've entered the correct email",
  "auth/weak-password": "Your password should be at least 6 characters",
  "auth/wrong-password": "The password/email is incorrect, please try again",
  "auth/invalid-action-code": "This link has either expired or has been used already, please generate another link",
  "auth/requires-recent-login": "Please log in again before trying this"
};

export default errorDictionary;
