import React, { useState } from "react";
import ScoreCards from "../../components/Performance/ScoreCards";
import { useSiteStations } from "../../hooks/use-site-stations";
import { SpecialtiesListNoEmoji } from "../../helpers/specialtiesList";
import { typeList } from "../../helpers/typesList";
//TODO
/* 
once Frankie gives okay:
hide new features and update the website
unhide new features in local version and repopulate, test out backend functions and webhooks
make sure new submissions get station type and specialty added to make it easily queryable
pull the number of stations done and then get all the average

make the progressbar realistic

integrate essential points

build the table for stations
pull the station attempts, who submitted, 
*/

function Performance({ data }) {
  const [tab, setTab] = useState("type");
  const { nodes } = useSiteStations();
  console.log("performance opened")
  return (
    <div data-w-tab="Performance" class="w-tab-pane w--tab-active">
      <div class="div-block-2">
        <h2 class="h2-account-page">Performance</h2>
        <div
          data-current="Station Type"
          data-easing="ease"
          data-duration-in="300"
          data-duration-out="100"
          class="w-tabs"
        >
          <div class="tabs-menu-4 w-tab-menu">
            <a
              class={
                tab == "type"
                  ? "performance-filter w-inline-block w-tab-link w--current"
                  : "performance-filter w-inline-block w-tab-link"
              }
              onClick={() => setTab("type")}
            >
              <div>Station Type</div>
            </a>
            <a
              class={
                tab == "specialty"
                  ? "performance-filter w-inline-block w-tab-link w--current"
                  : "performance-filter w-inline-block w-tab-link"
              }
              onClick={() => setTab("specialty")}
            >
              <div>Speciality</div>
            </a>
          </div>
          <div class="tabs-content-3 w-tab-content">
            {tab == "type" ? (
              <div
                data-w-tab="Station Type"
                class="tab-pane-tab-1-2 w-tab-pane w--tab-active"
              >
                <div class="w-layout-grid grid-2">
                  {Object.entries(typeList).map((type) => {
                    const stations = data.data;
                    const id = type[0];
                    const title = type[1];
                    const filteredStations = stations.filter(
                      (station) => station.type == id
                    );
                    const stationsDone = filteredStations
                      .map((station) => station.stationId)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    const findAverage = (arr) => {
                      const { length } = arr;
                      return arr.reduce((acc, val) => {
                        return acc + val.percentage / length;
                      }, 0);
                    };
                    const averagePercentage = findAverage(filteredStations);
                    console.log(filteredStations);
                    return (
                      <ScoreCards
                        title={title}
                        totalStations={
                          nodes.filter((node) => node.type == id).length
                        }
                        stationsDone={stationsDone.length}
                        percentage={averagePercentage}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div data-w-tab="Speciality" class="tabs-content-3 w-tab-content">
                <div class="w-layout-grid grid-2">
                  {Object.entries(SpecialtiesListNoEmoji).map((specialty) => {
                    const id = specialty[0];
                    const title = specialty[1];
                    const stations = data.data;
                    const filteredStations = stations.filter(
                      (station) => station.specialty == id
                    );
                    const stationsDone = filteredStations
                      .map((station) => station.stationId)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    const findAverage = (arr) => {
                      const { length } = arr;
                      return arr.reduce((acc, val) => {
                        return acc + val.percentage / length;
                      }, 0);
                    };
                    const averagePercentage = findAverage(filteredStations);

                    return (
                      <ScoreCards
                        title={title}
                        totalStations={
                          nodes.filter((node) => node.Specialty == id).length
                        }
                        stationsDone={stationsDone.length}
                        percentage={averagePercentage}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Performance;
