import { ConstructionOutlined } from "@mui/icons-material";
import app from "../Firebase/app";
import initializeStripe from "./initialiseStripe";

export async function createCheckoutSession(uid, stripeId, plan) {
  async function checkout(price) {
    const firestore = app.firestore();
    const checkoutSessionRef = await firestore
      .collection("users")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        mode: "payment",
        price: price,
        success_url: `${window.location.origin}/purchaseSuccess`,
        cancel_url: window.location.origin,
        customer: stripeId,
        allow_promotion_codes: true,

      });

    checkoutSessionRef.onSnapshot(async (snap) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        const stripe = await initializeStripe();
        stripe.redirectToCheckout({ sessionId });
      }
    });
  }
  let priceId;
  switch (plan) {
    case 3:
      //console.log("3");
      priceId = "price_1LGVsNFYlaVPK4IAYTe3IWM8";
      checkout(priceId);
      break;
    case 6:
      //console.log("6");
      priceId = "price_1LGVsDFYlaVPK4IAoCT5yayq";
      checkout(priceId);
      break;
    case 12:
      //console.log("12");
      priceId = "price_1MRyaSFYlaVPK4IAUXVzUgff";
      checkout(priceId);
      break;
    case "beta":
      //console.log("beta");
      priceId = "price_1K8uvKFYlaVPK4IAy5BK9Gph";
      checkout(priceId);
      break;
    case "3-referred":
      //console.log("3-referred");
      priceId = "price_1LGVtbFYlaVPK4IAwKMik3HO";
      checkout(priceId);
      break;
    case "6-referred":
      //console.log("6-referred");
      priceId = "price_1LGVtJFYlaVPK4IAukELhg5T";
      checkout(priceId);
      break;
    case "12-referred":
      //console.log("12-referred");
      priceId = "price_1MRyapFYlaVPK4IAavjReoZx";
      checkout(priceId);
      break;
    default:
      break;
  }
}
