import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import {
  MedicineList,
  OthersList,
  PlaylistList,
  SpecialtyList,
  SurgeryList,
} from "../../containers/Browse/ButtonLists";
import Usps from "../../containers/Home/Usps";

function SpecialtySelection({ user }) {
  console.log(user);
  const [university, setUniversity] = useState("");
  useEffect(() => {
    if (user) {
      const userUni = user.university;
      if (userUni && userUni.name) {
        setUniversity(userUni.name);
      }
    }
  }, [user]);
  const [currentTab, setCurrentTab] = useState("medicine");
  function renderSwitch(param) {
    switch (param) {
      case "medicine":
        return <MedicineList />;
        break;
      case "surgery":
        return <SurgeryList />;
        break;
      case "specialties":
        return <SpecialtyList />;
        break;
      case "other":
        return <OthersList />;
        break;
      case "playlist":
        return <PlaylistList />;
        break;

      default:
        break;
    }
  }
  return (
    <div>
      <div class="section-browse wf-section dashboard-gap">
        <div class="w-container dashboard-gap">
          <div
            data-current="Tab 1"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            class="browse-tab w-tabs"
          >
            <div class="tabs-menu w-tab-menu">
              <a
                onClick={() => setCurrentTab("medicine")}
                class={
                  currentTab == "medicine"
                    ? "tab w-inline-block w-tab-link w--current"
                    : "tab w-inline-block w-tab-link"
                }
              >
                <div>Medicine</div>
              </a>
              <a
                onClick={() => setCurrentTab("surgery")}
                class={
                  currentTab == "surgery"
                    ? "tab w-inline-block w-tab-link w--current"
                    : "tab w-inline-block w-tab-link"
                }
              >
                <div>Surgery</div>
              </a>
              <a
                onClick={() => setCurrentTab("specialties")}
                class={
                  currentTab == "specialties"
                    ? "tab w-inline-block w-tab-link w--current"
                    : "tab w-inline-block w-tab-link"
                }
              >
                <div>Specialities</div>
              </a>
              <a
                onClick={() => setCurrentTab("other")}
                class={
                  currentTab == "other"
                    ? "tab w-inline-block w-tab-link w--current"
                    : "tab w-inline-block w-tab-link"
                }
              >
                <div>Other</div>
              </a>
              {university ==
                "University College London, University of London" && (
                <a
                  onClick={() => setCurrentTab("playlist")}
                  class={
                    currentTab == "playlist"
                      ? "tab w-inline-block w-tab-link w--current"
                      : "tab w-inline-block w-tab-link"
                  }
                >
                  <div>UCL Stations</div>
                </a>
              )}
              <a
                onClick={() => navigate("/allStations")}
                class="tab w-inline-block w-tab-link w--allStations"
              >
                <div>All Stations</div>
              </a>
            </div>
            <div class="tabs-content w-tab-content">
              {renderSwitch(currentTab)}
            </div>
          </div>
        </div>
      </div>
      <Usps/>
    </div>
  );
}

export default SpecialtySelection;
