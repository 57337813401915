import { useStaticQuery, graphql } from "gatsby";

export const useSitePlaylists = () => {
  const { allStrapiPlaylists } = useStaticQuery(
    graphql`
      query SitePlaylists {
        allStrapiPlaylists(sort: { fields: title, order: ASC }) {
          nodes {
            university
            title
          }
        }
      }
    `
  );
  return allStrapiPlaylists;
};
