import React, { useState } from "react";
import { navigate } from "gatsby";
import LoadingWall from "../../components/LoadingWall";
import { useSitePlaylists } from "../../hooks/use-site-playlists";

export function MedicineList() {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && <LoadingWall />}
      <div class="w-tab-pane w--tab-active">
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/cardiorespiratory");
          }}
        >
          <h1 class="heading-2">Cardiorespiratory 🫀</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/gastro");
          }}
        >
          <h1 class="heading-2">Gastrointestinal 🤮</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/neuro");
          }}
        >
          <h1 class="heading-2">Neurology 🧠</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/rheum");
          }}
        >
          <h1 class="heading-2">Rheumatology 🤷</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/endo");
          }}
        >
          <h1 class="heading-2">Endocrinology 🏃</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/id");
          }}
        >
          <h1 class="heading-2">Infectious disease 🦟</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/haem");
          }}
        >
          <h1 class="heading-2">Haematology 🩸</h1>
        </div>
      </div>
    </div>
  );
}
export function SurgeryList() {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && <LoadingWall />}
      <div class="w-tab-pane w--tab-active">
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/breast");
          }}
        >
          <h1 class="heading-2">Breast 🤱</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/generalsurg");
          }}
        >
          {" "}
          <h1 class="heading-2">General Surgery 💉</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/urology");
          }}
        >
          {" "}
          <h1 class="heading-2">Urology 🍆</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/vascular");
          }}
        >
          {" "}
          <h1 class="heading-2">Vascular Surgery 🪡</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/traumaortho");
          }}
        >
          {" "}
          <h1 class="heading-2">Trauma and Orthopaedics 🦴</h1>
        </div>
      </div>
    </div>
  );
}
export function SpecialtyList() {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && <LoadingWall />}

      <div class="w-tab-pane w--tab-active">
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/derm");
          }}
        >
          {" "}
          <h1 class="heading-2">Dermatology 💁‍♀️</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/ent");
          }}
        >
          {" "}
          <h1 class="heading-2">ENT 👂</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/gp");
          }}
        >
          {" "}
          <h1 class="heading-2">GP 👨‍⚕️</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/gynae");
          }}
        >
          {" "}
          <h1 class="heading-2">Gynaecology 😰</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/obstetrics");
          }}
        >
          {" "}
          <h1 class="heading-2">Obstetrics 🤰</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/ophthal");
          }}
        >
          {" "}
          <h1 class="heading-2">Ophthalmology 👁</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/paeds");
          }}
        >
          {" "}
          <h1 class="heading-2">Paediatrics 👶</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/psych");
          }}
        >
          {" "}
          <h1 class="heading-2">Psychiatry 😐</h1>
        </div>
      </div>
    </div>
  );
}
export function OthersList() {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && <LoadingWall />}

      <div class="w-tab-pane w--tab-active">
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/ethics_law");
          }}
        >
          {" "}
          <h1 class="heading-2">Ethics and law 👩‍⚖️</h1>
        </div>
        <div
          class="browse-card"
          onClick={() => {
            setLoading(true);
            navigate("/miscellaneous");
          }}
        >
          {" "}
          <h1 class="heading-2">Miscellaneous 🗣</h1>
        </div>
      </div>
    </div>
  );
}

export function PlaylistList() {
  const [loading, setLoading] = useState(false);
  const { nodes } = useSitePlaylists();
  console.log(nodes);
  return (
    <div>
      {loading && <LoadingWall />}
      <div class="w-tab-pane w--tab-active">
        {nodes.map((playlist) => {
          return (
            <div
              class="browse-card"
              onClick={() => {
                setLoading(true);
                navigate(`/${playlist.title}`);
              }}
            >
              {" "}
              <h1 class="heading-2">{playlist.title}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
}
